.toolbar {
    display: flex;
    flex-direction: row;
    margin: 32px 0;
}

.toolbar .search {
    flex-grow: 1;
    flex-shrink: 1;
}

.toolbar .add {
    margin-left: auto;
    padding-left: 24px;
}

.load {
    margin-top: 24px;
    text-align: center;
}

.description {
    color: rgba(0, 0, 0, 0.45);
}
