.layout .info h2 {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 30px;
}

.layout .info p {
    font-size: 18px;
    margin-bottom: 14px;
}

.layout .info p:last-child {
    margin-top: 56px;
    font-weight: 600;
    color: rgba(75, 64, 122, 1);
}

.layout .info p i {
    margin-right: 12px;
}

.local {
    font-size: 18px;
}

.local h1 {
    margin-bottom: 12px;
    font-weight: bold;
}

.local .closing {
    margin-top: -12px;
    margin-bottom: 12px;
    font-weight: bold;
    color: red;
}

.local .signin {
    margin-bottom: 40px;
}

.local .signin a {
    font-size: 18px;
    color: #009b3f !important;
    font-weight: 600;
    text-decoration: underline;
}

.local .field {
    margin-bottom: 24px !important;
}

.local .legal {
    font-size: 16px;
    line-height: 24px;
}

.local .legal a {
    color: #000;
    font-weight: 500;
}

.social {
    font-size: 18px;
    margin-top: 24px;
    line-height: 22px;
}

.social button {
    padding: 0;
    height: auto;
    font-size: 18px;
    color: #009b3f !important;
    font-weight: 600;
    text-decoration: underline;
}

.success strong {
    color: #009b3f !important;
    font-weight: 600;
}
