.footer {
    line-height: 18px;
}

.footer .links a {
    margin-right: 6px;
    white-space: nowrap;
}

.footer a {
    color: rgba(0, 0, 0, 0.35);
    font-size: 11px;
}

.desktop .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.desktop .footer .links a {
    margin-right: 12px;
}

.desktop .footer .copyright {
    margin-left: auto;
    line-height: 32px;
}
