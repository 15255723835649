.full {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    font-family: sans-serif;
    font-size: 24px;
    color: #290b39;
}

.full > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
}

.full > div > img {
    height: 200px;
    padding-right: 36px;
}

.full > div > div > img {
    width: 100px;
}

.basic {
    text-align: center;
}

.basic > div {
    margin-top: 56px;
    text-align: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 16px;
    color: #290b39;
}

.basic > img {
    width: 100px;
}
