.local {
    font-size: 18px;
}

.local h1 {
    margin-bottom: 12px;
    font-weight: bold;
}

.local .signup {
    margin-bottom: 40px;
}

.local .signup a {
    font-size: 18px;
    color: #009b3f !important;
    font-weight: 600;
    text-decoration: underline;
}

.local .field {
    margin-bottom: 24px !important;
}

.local .reset {
    float: right;
    margin: 8px 0 32px 0;
    font-size: 16px;
    color: #009b3f !important;
    font-weight: 500;
    text-decoration: underline;
}

.social button {
    margin-bottom: 12px;
}

.social button.google {
    border-color: #4285f4;
    color: #4285f4;
}

.social button.google:hover {
    background-color: #4285f4;
    color: #fff;
}

.social button.facebook {
    border-color: #3b5998;
    color: #3b5998;
}

.social button.facebook:hover {
    background-color: #3b5998;
    color: #fff;
}

.info {
    padding-left: 32px;
    padding-right: 32px;
}

.info img {
    width: 100%;
}
