.sidebar {
    padding: 16px 24px;
    border: 1px solid #e0e0e1;
    border-radius: 8px;
    background: #fff;
}

.sidebar > div:not(:last-child) {
    margin-bottom: 24px;
}
