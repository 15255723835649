.menu h2 {
    font-size: 21px;
    font-weight: 600;
    border-bottom: 1px solid #e0e0e1;
    margin-bottom: 24px;
    margin: 0 -24px;
    padding: 0 24px 12px 24px;
    margin-bottom: 36px;
}

.ads {
    margin: 0 -24px;
}

.menu.entity > span {
    display: block;
    margin: 24px -24px 24px -24px;
    border-bottom: 1px solid #e0e0e1;
}

.menu ul {
    list-style: none;
    font-size: 16px;
    padding-left: 0;
}

.menu.entity ul {
    margin-bottom: 0;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
}

.menu ul li {
    margin-bottom: 16px;
}

.menu.entity ul li {
    margin-bottom: 0;
    line-height: 24px;
}

.menu ul li a:active,
.menu ul li a:hover {
    color: rgba(0, 0, 0, 0.85);
}

.menu ul li span {
    margin-right: 16px;
}

.menu ul li .icon {
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid rgba(0, 0, 0, 0.85);
    background-color: #fff;
}

.menu ul li button {
    color: rgba(0, 0, 0, 0.65) !important;
}
