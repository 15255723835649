.top {
    position: relative;
    margin-top: -64px;
    padding: 70px 0;
    color: #fff;
    text-align: center;
}

.top:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 25, 43, 0.45);
    z-index: 1;
    content: '';
}

.top .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #290b39;
    background-size: cover;
    background-position: center center;
    background-image: url(./images/background-sm.jpg);
}

.top .headline {
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 2;
    padding: 24px;
}

.top .headline h1 {
    color: #fff;
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 52px;
    font-weight: 600;
}

.top .headline h2 {
    color: #fff;
    margin-bottom: 32px;
    font-size: 24px;
    opacity: 0.85;
}

.top .headline .buttons {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 80px;
}

.top .headline .buttons .button {
    font-size: 24px;
    height: 60px;
    padding: 0 24px;
}

.top .headline .buttons .button:hover {
    color: #011837;
    background: #fff !important;
}

.top .headline img {
    margin-top: 64px;
    max-height: 600px;
    max-width: 100%;
}

.section {
    padding: 140px 24px 120px 24px;
}

.section .image img {
    margin-top: 80px;
    margin-bottom: 20px;
    width: 100%;
}

.section .message div {
    height: 100%;
    margin-right: 8px;
    text-align: center;
}

.section .message div h3 {
    margin-bottom: 60px;
    font-size: 42px;
    line-height: 38px;
    font-weight: 600;
}

.section .message div h3 a {
    font-size: 42px;
    line-height: 38px;
    font-weight: 600;
}

.section .message div p {
    font-size: 20px;
    margin-bottom: 12px;
}

.section .message div p.link {
    margin-top: 36px;
    opacity: 1;
}

.section.doctors .message {
    order: 1;
}

.section.doctors .image {
    order: 2;
}

.section.patients {
    background-color: #290b39;
    color: #fff;
}

.section.patients .message div h3 a {
    color: #fff;
}

.section.patients .message div p:not(.link) {
    opacity: 0.65;
}

.section.patients .image img {
    display: none;
}

.section.services {
    background-color: rgba(157, 150, 216, 0.05);
}

.section.services .message {
    margin-bottom: 60px;
}

.section.services .message div h3 {
    font-size: 38px;
    margin-bottom: 24px;
}

.section.services .message div p {
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .top .back {
        background-image: url(./images/background.jpg);
    }

    .top .headline {
        background-position: center bottom;
    }

    .top .headline h1 {
        margin-top: 160px;
        margin-bottom: 32px;
        margin-left: 80px;
        font-size: 56px;
    }

    .top .headline h2 {
        margin-bottom: 144px;
        margin-left: 48px;
        margin-left: 80px;
        font-size: 24px;
    }

    .top .headline .buttons {
        margin-bottom: 112px;
        margin-left: 80px;
    }

    .section {
        padding: 140px 24px 160px 24px;
        padding-left: 60px;
        padding-right: 60px;
    }

    .section.doctors .message {
        order: 2;
    }

    .section.doctors .image {
        order: 1;
    }

    .section.doctors .message div {
        text-align: right;
    }

    .section.doctors .image img {
        margin-top: 0px;
    }

    .section.patients .message div {
        text-align: left;
    }

    .section.patients .image img {
        display: block;
    }

    .section.services .message div h3 {
        font-size: 42px;
    }

    .section.services .video {
        max-width: 800px;
    }
}
