.post {
    margin: 0 0 16px 0;
    padding: 16px;
    background-color: #f8fafb;
    border: 1px solid #f8fafb !important;
    border-radius: 16px;
}

.post .name {
    font-size: 12px;
}

.post .date {
    font-size: 12px;
    margin-top: -5px;
    display: block;
}

.post .status {
    position: absolute;
    right: 8px;
}

.post .message {
    color: #000;
    line-height: 22px;
    margin-top: -8px;
    overflow: hidden;
}

.post .message a {
    color: #009b3f;
    font-weight: 600;
}

.post .message .more {
    color: rgba(0, 0, 0, 0.6) !important;
    margin-left: 16px;
    cursor: pointer;
}

.post .action i {
    margin-right: 8px;
}
