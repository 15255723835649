.title {
    font-size: 16px;
}

p.warning {
    margin-top: -6px;
    margin-bottom: 24px;
    font-style: italic;
    line-height: 13px;
    font-weight: 600;
    color: rgb(92, 106, 194);
}

.form label {
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
    font-size: 16px;
}

.type {
    width: 100%;
}

.measurement {
    width: 100%;
}

.gender {
    width: 100%;
}

.template {
    width: 100%;
}

.layout .calculator h3 {
    font-weight: 600;
}

.tabs {
    margin-top: 16px;
    margin-bottom: 16px;
}

.layout .calculator .info {
    margin-top: 48px;
    font-size: 16px;
}

.layout .calculator .info ul {
    padding-left: 16px;
}
