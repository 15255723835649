.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
}

.header a {
    color: #fff !important;
}

.header .logo {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
}

.header .logo .icon {
    margin-right: 8px;
    padding: 0 1px;
}

.header .logo .icon img {
    height: 16px;

    margin-top: -3px;
}

.header .menu {
    margin-left: auto;
    line-height: 64px;
    background-color: transparent;
}

.notification {
    cursor: pointer;
}

.notification.call {
    cursor: default;
}

.notification.call button {
    margin-right: 16px;
}

.languages button {
    color: #fff;
}
