.diary .date {
    margin-top: 8px;
    margin-bottom: 24px;
}

.diary .date .day {
    font-size: 18px;
    margin-right: 36px;
}

.diary .template {
    margin-top: 42px;
    margin-bottom: 24px;
}

.diary .template h2 {
    font-size: 16px;
    color: #009b3f;
    margin-bottom: 0;
    font-weight: 600;
}

.diary .template p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
}

.diary .answer {
    overflow: hidden;
    margin-bottom: 16px;
}

.diary .buttons {
    margin-top: 36px;
}

.diary .buttons .delete {
    color: #ff4d4f !important;
    float: right;
}
