.diary .header {
    margin-bottom: 32px;
    display: flex;
}

.diary .header .title h1 {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 21px;
}

.diary .header .title p {
    font-size: 15px;
    margin-bottom: 0;
}

.diary .header .back {
    flex: 0 0 auto;
    margin-left: auto;
}

.diary .questions h2 {
    margin-bottom: 0;
}

.diary .question button {
    float: right;
}

.diary .buttons {
    margin-top: 56px;
}

.diary .buttons .delete {
    color: #ff4d4f !important;
    float: right;
}
