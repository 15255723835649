.date > div:first-child {
    white-space: nowrap;
    overflow: visible;
}

.time label {
    color: #f8fafb;
}

.value {
    display: inline-block !important;
}

.metadata {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 16px;
}

.bmi {
    font-weight: 600;
    color: #ff4d4f;
    margin-bottom: 0;
}

.bmi.normal {
    color: #1aaa55;
}

.weight {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 0;
}

.diary {
    margin-top: 24px;
}

.diary a {
    color: #009b3f !important;
    font-weight: 600;
    text-decoration: underline;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer .back {
    margin-left: auto;
}
