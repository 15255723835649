.user .header {
    margin-bottom: 32px;
    display: flex;
}

.user .header .title h1 {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 21px;
}

.user .header .title p {
    font-size: 15px;
    margin-bottom: 0;
}

.user .header .back {
    flex: 0 0 auto;
    margin-left: auto;
}

.invited {
    margin-top: 24px;
}

.invited h2 {
    color: #009b3f !important;
}

.invited p {
    font-size: 16px;
}

.invited p strong {
    font-weight: 600;
}

.invited .cancel {
    padding-left: 0;
    text-decoration: underline;
    margin-top: 24px;
    color: #ff4d4f !important;
}

span.username {
    opacity: 0.7;
}

.buttons {
    margin-top: 24px;
}

.buttons .delete {
    color: #ff4d4f !important;
    float: right;
}
