.menu {
    list-style: none;
    padding: 0;
}

.menu li {
    display: inline-block;
    border-right: 1px solid #ccc;
    margin: 0 8px 0 0;
    padding: 0 8px 0 0;
}

.menu li:last-child {
    display: inline-block;
    border-right: 0 none;
    margin: 0;
    padding: 0;
}

.menu li a.selected {
    color: #39b869;
    font-weight: 600;
}

.menu li i {
    font-size: 10px;
}

.menu li .disabled {
    opacity: 0.6;
}
