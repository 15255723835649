.invitation {
    margin-top: 24px;
    font-size: 15px;
    font-style: italic;
}

.link {
    font-size: 16px;
    margin-top: 36px;
    margin-bottom: 36px;
}

.link a {
    color: #009b3f !important;
    font-weight: 600;
    text-decoration: underline;
}

.buttons {
    margin-top: 48px;
}
