.container {
    display: flex;
    margin-top: 50px;
    margin-bottom: 80px;
    align-items: 'center';
    position: relative;
    vertical-align: center;
    font-size: 16px;

    .image {
        text-align: center;

        .avatar {
            display: 'inline-block';
            vertical-align: center;
            margin-bottom: 15px;
            margin-top: 15px;
            width: 120px;
            height: 120px;
        }
    }

    .information {
        flex: 1;
        text-align: left;

        h3 {
            font-weight: 600;
        }
    }
}
