.toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 12px 0 12px 0;
}

p.warning {
    margin-top: -6px;
    margin-bottom: 24px;
    font-style: italic;
    line-height: 13px;
    font-weight: 600;
    color: rgb(92, 106, 194);
}

.toolbar .selector {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100px;
}

.toolbar .selector > div {
    display: block;
}

.toolbar .add {
    margin-left: auto;
    padding-left: 24px;
}

.template {
    display: block;
    margin-top: 36px;
}
