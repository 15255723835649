.templates {
    text-align: left;
}

.templates h2 {
    font-size: 16px;
    margin-top: 42px;
    margin-bottom: 0;
    font-weight: 600;
}

.templates h2.standard {
    color: #009b3f;
}

.templates h2.person {
    color: #5c6ac2;
}

.templates h2.user {
    color: #290b39;
}

.templates .type h2 {
    font-size: 16px;
    margin-top: 42px;
    margin-bottom: 30px;
    font-weight: 400;
    text-align: left;
}

.templates .type button {
    margin-top: 16px;
    margin-right: 16px;
}

.templates .type .card {
    margin-top: 8px 12px;
    padding: 16px;
    text-align: center;
    font-size: 16px;
    border: 1px solid rgba(40, 11, 56, 0.3);
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.templates .type .card.standard {
    color: #009b3f;
    border: 1px solid #009b3f;
}

.templates .type .card.person {
    color: #5c6ac2;
    border: 1px solid #5c6ac2;
}

.templates .type .card.user {
    color: #290b39;
    border: 1px solid #290b39;
}

.templates .type .card p {
    margin: 0;
}

.templates .type .card p:first-child {
    font-weight: 600;
}

.templates .type .card p:last-child {
    margin-top: 4px;
    font-size: 14px;
    opacity: 0.8;
}

.templates .templates h2 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 30px;
    font-weight: 400;
}

.templates .templates .item {
    cursor: pointer;
}

.templates .templates .item h4 {
    font-size: 16px;
}

.templates .templates .item ul li {
    padding-right: 2px;
}

.templates .load {
    margin-top: 16px;
    text-align: center;
}

.templates .empty {
    font-size: 16px;
    margin-top: 48px;
}

.templates .empty a {
    color: #009b3f !important;
    font-weight: 600;
    text-decoration: underline;
}

@media (min-width: 768px) {
    .templates .type .card {
        text-align: left;
    }
}
