.new {
    position: absolute;
    right: 36px;
    margin-top: -40px;
}

.load {
    margin-top: 24px;
    text-align: center;
}

.ads {
    margin: 24px 0 16px 0;
    background-color: rgba(41, 11, 57, 0.03);
    border: 1px solid rgba(41, 11, 57, 0.3);
}

.ads i {
    color: rgba(41, 11, 57, 1) !important;
}
