.layout h1 {
    font-weight: bold;
}

.layout .title {
    font-size: 16px;
}

.layout .desc {
    line-height: 16px;
    display: inline-block;
}
