.post {
    margin: 0 0 16px 0;
    padding: 16px;
    background-color: #fff;
    border: 1px solid rgba(41, 11, 57, 0.25) !important;
    border-radius: 4px;
}

.post h4 {
    line-height: 16px !important;
    font-size: 14px !important;
}

.post .date {
    font-size: 12px;
}

.post .status {
    position: absolute;
    right: 8px;
}

.post .title,
.post .title a {
    color: #000;
    font-weight: 600;
    line-height: 22px;
    overflow: hidden;
}

.post h1.title {
    font-size: 24px;
    line-height: 30px;
}

.post .message {
    color: #000;
    line-height: 22px;
    overflow: hidden;
}

.post .message a {
    color: #009b3f;
    font-weight: 600;
}

.post .message .more {
    color: rgba(0, 0, 0, 0.6) !important;
    margin-left: 16px;
    cursor: pointer;
}

.post .image {
    margin: 16px -16px 0 -16px;
    padding: 0 16px;
}

.post .image img {
    max-width: 100%;
    max-height: 250px;
}

.post .conditions {
    margin-top: 16px;
}

.post .action i {
    margin-right: 8px;
}

.post a.action i {
    color: rgba(0, 0, 0, 0.45);
    margin-right: 0;
}
