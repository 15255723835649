.toolbar {
    display: flex;
    flex-direction: row;
    margin: 12px 0 12px 0;
}

.toolbar .storage {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100px;
}

.toolbar .storage .level {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
}

.toolbar .add {
    margin-left: auto;
    padding-left: 24px;
}

.description {
    color: rgba(0, 0, 0, 0.45);
}

.button {
    text-align: center;
    margin-top: 24px;
}
