.layout {
    text-align: center;
}

.layout h1 {
    margin-bottom: 24px;
    font-weight: bold;
}

.layout .steps {
    display: none;
}

.layout .intro h2 {
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
}

.layout .intro p {
    font-size: 14px;
}

.layout .intro button {
    margin-top: 48px;
    font-size: 22px;
    height: 44px;
    padding: 0 24px;
}

.layout .question label {
    font-size: 20px;
}

.layout .question .answer {
    margin-top: 48px;
}

.layout .question .answer button {
    font-size: 22px;
    height: 44px;
    padding: 0 24px;
}

.layout .question .answer button:first-child {
    margin-right: 16px;
}

.layout .question .navigation {
    margin-top: 120px;
}

.layout .question .navigation button {
    color: rgba(0, 0, 0, 0.65) !important;
}

.layout .question .navigation .counter {
    line-height: 40px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0 16px;
}

.layout .person .field {
    margin-bottom: 32px !important;
}

.layout .person .field > div:first-child {
    text-align: center;
}

.layout .person label {
    font-size: 20px;
}

.layout .person .buttons button {
    margin-top: 16px;
    font-size: 22px;
    height: 44px;
    padding: 0 24px;
}

.layout .person .navigation {
    margin-top: 60px;
}

.layout .person .navigation button {
    color: rgba(0, 0, 0, 0.65) !important;
}

.layout .person .navigation .counter {
    line-height: 40px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0 16px;
}

.layout .result button {
    margin-top: 28px;
}

.national {
    margin-bottom: 24px;
}

.countries {
    width: 100%;
}
