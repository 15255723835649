@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Nunito Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8fafb;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    font-size: 2em;
}

a {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
}

.alternative {
    background-color: rgb(75, 64, 122);
    border-color: rgb(75, 64, 122);
    color: #fff;
}

.alternative:hover,
.alternative:focus {
    background-color: rgba(75, 64, 122, 0.85);
    border-color: rgba(75, 64, 122, 0.85);
    color: #fff;
}

.alternative.ant-btn-background-ghost {
    background-color: transparent !important;
    border-color: rgb(75, 64, 122);
    color: rgb(75, 64, 122);
}

.alternative.ant-btn-background-ghost :hover,
.alternative.ant-btn-background-ghost :focus {
    border-color: rgb(154, 127, 168);
    color: rgb(154, 127, 168);
}

.panel {
    height: 100%;
}

.panel .panel-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.panel .panel-header h1,
.panel .panel-header h1 a {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 21px;
}

.panel .panel-header p {
    font-size: 15px;
    margin-bottom: 0;
}

.panel .panel-header p a {
    color: #009b3f !important;
    font-weight: 600;
    text-decoration: underline;
}

.panel .panel-header .panel-header-back {
    flex: 0 0 auto;
    margin-left: auto;
}

.panel .panel-header .panel-header-back button {
    margin-left: 8px;
}

@media (min-width: 1200px) {
    .panel {
        padding: 24px;
        border: 1px solid #e0e0e1;
        border-radius: 8px;
        background: #fff;
        height: 100%;
        min-height: 500px;
    }

    .panel .panel-header {
        margin: -24px -24px 24px -24px;
        border-bottom: 1px solid #e0e0e1;
        padding: 16px 24px 12px;
    }

    .panel .panel-header h1 {
        display: inline;
    }

    .panel .panel-header p {
        display: inline;
        margin-left: 20px;
    }
}

.empty {
    margin-top: 36px;
}

.empty span {
    display: block;
    margin-bottom: 16px;
}

.professional-ads .ant-notification-notice-message {
    font-weight: 600;
}

.professional-ads .ant-notification-notice-description strong {
    font-size: 16px;
    margin-right: 16px;
}

.professional-ads .ant-notification-notice-description button {
    padding-left: 0;
}

.professional-ads .ant-notification-notice-description button span {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
}

/*** ANTD STYLES ***/

#root {
    height: 100%;
}

#root .ant-list-empty-text,
.root-modal .ant-list-empty-text {
    font-size: 16px;
}

#root .ant-btn-link,
.root-modal .ant-btn-link {
    color: #000;
}

#root .ant-form-item,
.root-modal .ant-form-item {
    margin-bottom: 16px;
}

#root .ant-form label,
.root-modal .ant-form label {
    font-size: 16px;
}

#root .ant-list-item-meta-title {
    margin-bottom: 0;
    font-size: 16px;
}

#root .ant-list-split a .ant-list-item {
    border-bottom: 1px solid #e8e8e8;
}

#root .ant-list-split a:last-child .ant-list-item {
    border-bottom: none;
}

button:not(.ant-btn-icon-only) i {
    margin-right: 8px;
}

#root .ant-menu-item .anticon {
    margin-right: 0;
}

#root .ant-card-body {
    padding: 22px 16px;
}

#root .ant-progress-inner {
    background-color: #ddd;
}

#root .ant-result .ant-result-title {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 24px;
}

#root .ant-result .ant-result-subtitle {
    font-size: 18px;
}

#root .ant-slider .ant-slider-rail {
    background-color: #e1e1e1;
}

#root .ant-slider .ant-slider-dot {
    top: -6px;
    width: 16px;
    height: 16px;
}

#root .ant-slider .ant-slider-handle {
    margin-top: -6px;
    margin-left: 4px;
    width: 16px;
    height: 16px;
}

#root .ant-slider .ant-slider-mark .ant-slider-mark-text:first-child {
    padding-left: 16px;
}

#root .ant-slider .ant-slider-mark .ant-slider-mark-text:last-child {
    padding-right: 16px;
}

#root .ant-tabs-nav-container {
    font-size: 16px;
}

#root .ant-calendar-disabled-cell .ant-calendar-date {
    color: rgba(0, 0, 0, 0.3);
}

#root .ant-fullcalendar-fullscreen .ant-fullcalendar-header,
#root .ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body {
    padding-left: 0;
    padding-right: 0;
}

#root .ant-fullcalendar-selected-day .ant-fullcalendar-date {
    background: transparent;
}

#root .ant-fullcalendar-selected-day .ant-fullcalendar-date .ant-fullcalendar-value {
    color: rgba(0, 0, 0, 0.65);
    background: transparent;
}

#root .ant-fullcalendar-date:hover {
    background-color: rgba(154, 127, 168, 0.3);
}

#root .ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
#root .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
    color: rgba(0, 0, 0, 0.3);
}

/*** QUILL - RICH TEXT EDITOR ***/

#root .ql-editor,
.root-drawer .ql-editor {
    min-height: 200px;
}
