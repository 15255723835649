.list {
    min-height: 200px;
}

.list .item {
    cursor: pointer;
}

.list .measure {
    color: rgba(0, 0, 0, 0.65);
}

.list .metadata {
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    font-size: 12px;
}

.list .age {
    font-size: 12px;
}
