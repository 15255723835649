.diary .date {
    margin-top: 8px;
    margin-bottom: 24px;
}

.diary .date .day {
    font-size: 18px;
    margin-right: 36px;
    font-weight: 600;
}

.diary .date .day {
    text-transform: capitalize;
}

.diary textarea {
    font-size: 16px;
}

.diary .buttons {
    margin-top: 24px;
}

.diary .buttons .delete {
    color: #ff4d4f !important;
    float: right;
}
