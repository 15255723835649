.invitation {
    margin-top: 24px;
    font-size: 15px;
    font-style: italic;
}

.person {
    font-size: 16px;
    margin-top: 36px;
    margin-bottom: 36px;
}

.person .linked {
    font-weight: 600;
}

.person .selector button {
    margin-top: 16px;
    margin-right: 16px;
}

.person .selector .persons {
    margin-top: 16px;
    width: 100%;
}

.buttons {
    margin-top: 48px;
}
