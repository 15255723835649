.notification i {
    margin-right: 16px;
}

.notification button:first-child {
    margin-top: 32px;
    margin-bottom: 8px;
}

.tabs {
    margin-top: 16px;
}

.buttons {
    margin-top: 36px;
}

.buttons .delete {
    color: #ff4d4f !important;
    float: right;
}
