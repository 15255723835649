.diaries .toolbar {
    display: flex;
    flex-direction: row;
    margin: 32px 0;
}

.diaries .toolbar .search {
    flex-grow: 1;
    flex-shrink: 1;
}

.diaries .toolbar .add {
    margin-left: auto;
    padding-left: 24px;
}

.diaries .item {
    cursor: pointer;
}

.diaries .item h4 {
    font-size: 16px;
}

.diaries .load {
    margin-top: 24px;
    text-align: center;
}

.diaries .description {
    color: rgba(0, 0, 0, 0.45);
}

.diaries .external {
    margin-left: 8px;
}
