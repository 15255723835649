.top {
    position: relative;
    margin-top: -64px;
    padding: 70px 0;
    color: #fff;
    text-align: center;
}

.top:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 25, 43, 0.45);
    z-index: 1;
    content: '';
}

.top .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #290b39;
    background-size: cover;
    background-position: center center;
    background-image: url(../images/background-sm.jpg);
}

.top .headline {
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 2;
    padding: 24px;
}

.top .headline h1 {
    color: #fff;
    margin-top: 80px;
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
}

.top .headline h2 {
    color: #fff;
    margin-bottom: 32px;
    font-size: 24px;
    opacity: 0.85;
}

.top .headline .buttons {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 120px;
}

.top .headline .buttons .button {
    font-size: 24px;
    height: 60px;
    padding: 0 24px;
}

.top .headline .buttons .button:hover {
    color: #011837;
    background: #fff !important;
}

.section {
    padding: 80px 24px 80px 24px;
}

.section .image {
    text-align: center;
}

.section .image img {
    max-height: 600px;
    max-width: 100%;
}

.section .message {
    height: 100%;
    margin-right: 8px;
    text-align: center;
}

.section .message h3 {
    margin-bottom: 36px;
    font-size: 34px;
    font-weight: 600;
}

.section .message p {
    font-size: 20px;
    margin-bottom: 16px;
}

.section.left .image {
    margin-top: 80px;
}

.section.right .image {
    margin-bottom: 80px;
}

.section.full {
    padding: 100px 24px 120px 24px;
    background-color: #290b39;
    color: #fff;
}

.section.full.text {
    padding-top: 120px;
    padding-bottom: 160px;
}

.section.full h3 {
    color: #fff;
}

.section.full p {
    opacity: 0.65;
}

.section.full .image {
    margin-top: 80px;
}

.section.services {
    background-color: #290b39;
}

.section.services .message {
    margin-bottom: 60px;
    color: #fff;
}

.section.services .message div h3 {
    font-size: 38px;
    margin-bottom: 24px;
    color: #fff;
}

.section.services .message div p {
    margin-bottom: 0;
}

.section.services .video {
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.section.demo {
    background-color: #fff;
    padding: 100px 24px 120px 24px;
}

.section.demo .form {
    margin-top: 140px;
    text-align: center;
    margin: 80px auto;
    max-width: 450px;
}

.section.demo .form label {
    margin-bottom: 8px;
    font-size: 20px;
    display: block;
}

.section.demo .form .email span span:last-child {
    background-color: transparent;
}

.section.demo .form p {
    margin-top: 4px;
    text-align: left;
}

.section.demo .success p {
    margin-top: 60px;
    font-size: 24px;
}

@media (min-width: 992px) {
    .top .back {
        background-image: url(../images/background.jpg);
    }

    .top .headline {
        background-position: center bottom;
    }

    .top .headline h1 {
        margin-top: 160px;
        margin-bottom: 32px;
        font-size: 56px;
    }

    .top .headline h2 {
        margin-bottom: 144px;
        font-size: 24px;
    }

    .top .headline .buttons {
        margin-bottom: 112px;
    }

    .section.right .message {
        margin-right: 48px;
        text-align: right;
    }

    .section.left .message {
        margin-left: 48px;
        text-align: left;
    }

    .section.full {
        padding: 80px 260px 140px 240px;
    }

    .section.full .image {
        margin-top: 60px;
    }

    .section.services .message div h3 {
        font-size: 42px;
    }

    .section.services .video {
        max-width: 800px;
    }
}
