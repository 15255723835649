.label {
    margin-bottom: 0 !important;
}

.value {
    display: inline-block !important;
}

.separator {
    line-height: 40px;
}

.metadata {
    color: rgba(0, 0, 0, 0.45);
}

.error {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    position: absolute;
}
