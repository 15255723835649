p.description {
    font-size: 16px;
    margin-bottom: 16px;
    color: #1aaa55;
}

p.description i {
    color: #1aaa55;
}

p.warning {
    font-size: 16px;
    margin-bottom: 16px;
    color: #ff4d4f;
}

p.warning i {
    color: #ff4d4f;
}

p.hint {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 36px;
}
