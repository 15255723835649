.chats .empty {
    margin-top: 24px;
}

.chats .empty span {
    display: block;
    width: 100%;
    font-size: 16px;
}

.chats .item {
    align-items: flex-start;
}

.chats .item .desc {
    display: inline-block;
    line-height: 18px;
}
