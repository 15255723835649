.contact .message {
    margin-top: 24px;
}

.contact .message p {
    font-size: 18px;
    margin-bottom: 8px;
}

.contact .message p a {
    color: #009b3f !important;
    font-weight: 600;
    text-decoration: underline;
}

.contact .image {
    text-align: center;
}

.contact .image img {
    margin-top: 48px;
    margin-bottom: 32px;
    max-width: 100%;
    max-height: 400px;
}

@media (min-width: 992px) {
    .contact .image img {
        margin-top: -48px;
        margin-bottom: 0;
    }
}
