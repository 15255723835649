.alerts {
    line-height: 24px;
}

.buttons {
    margin-top: 56px;
}

.buttons .delete {
    color: #ff4d4f !important;
    float: right;
}
