h1 {
    font-size: 24px;
    margin-bottom: 8px;
}

.tag {
    margin: 0 0 0 16px;
    font-size: 16px;
    line-height: 28px;
}

.tag.active {
    background-color: #52c41a;
    color: #fff;
}
