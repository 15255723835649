.header {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 32px;
    color: rgba(0, 0, 0, 0.85);
}

.plans .plan {
    padding: 32px;
    border: 1px solid #e0e0e1;
    border-radius: 8px;
    background: #fff;
    height: 100%;
}

.plans .plan h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    margin: 0 20px 0 0;
    color: #303030;
}

.plans .plan h2 span {
    margin-left: 16px;
}

.plans .plan .description {
    font-size: 16px;
    line-height: 24px;
    color: #303030;
    margin: 0 0 30px 0;
}

.plans .plan h3 {
    font-size: 16px;
    color: #505050;
    line-height: 40px;
    margin: 0 0 20px;
}

.plans .plan h3 strong {
    font-size: 24px;
    text-transform: uppercase;
    margin: 0 4px;
    font-weight: 700;
}

.plans .plan button {
    font-weight: 700;
}

.plans .plan.basic button {
    background-color: rgba(75, 64, 122, 0.65);
    border-color: rgba(75, 64, 122, 0.65);
    color: #fff;
}

.plans .plan.basic button:hover,
.plans .plan.basic button:focus {
    background-color: rgba(75, 64, 122, 0.85);
    border-color: rgba(75, 64, 122, 0.85);
    color: #fff;
}

.plans .plan.standard button {
    background-color: rgba(154, 127, 168, 0.65);
    border-color: rgba(154, 127, 168, 0.65);
    color: #fff;
}

.plans .plan.standard button:hover,
.plans .plan.standard button:focus {
    background-color: rgba(154, 127, 168, 0.85);
    border-color: rgba(154, 127, 168, 0.85);
    color: #fff;
}

.plans .plan.premium button {
    background-color: rgba(157, 150, 216, 0.65);
    border-color: rgba(157, 150, 216, 0.65);
    color: #fff;
}

.plans .plan.premium button:hover,
.plans .plan.premium button:focus {
    background-color: rgba(157, 150, 216, 0.85);
    border-color: rgba(157, 150, 216, 0.85);
    color: #fff;
}

.plans .plan .functionalities {
    margin-top: 50px;
    line-height: 24px;
    color: #505050;
    font-size: 16px;
}

.plans .plan .functionalities h4 {
    color: #009b3f;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid #e0e0e1;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.plans .plan .functionalities i {
    color: rgba(0, 155, 63, 0.55);
    margin-right: 16px;
}

.plans .plan .functionalities p.cancel {
    margin: 24px 0 0 0;
    color: #f5222d;
    font-size: 16px;
}

.plans .plan .functionalities p.extra {
    margin: 36px 0 0 0;
    font-size: 14px;
    opacity: 0.65;
    line-height: 16px;
}
