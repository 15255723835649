.chat {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.chat .messages {
    flex: 1;
}

.chat .messages .previous {
    margin: 24px 0 16px 0;
    text-align: center;
}

.chat .messages .list .message .sender {
    font-weight: 600;
    font-size: 15px;
    color: rgb(29, 28, 29);
}

.chat .messages .list .message .body p {
    margin-bottom: 4px;
    margin-right: 32px;
    font-size: 15px;
    line-height: 20px;
}

.chat .messages .list .message .body p a {
    color: #009b3f !important;
    text-decoration: underline;
}

.chat .messages .list .message .body p.success {
    font-size: 16px;
    margin-top: 8px;
}

.chat .messages .list .message .body p.success i {
    font-size: 36px;
    color: rgba(26, 170, 85, 0.65) !important;
    margin-top: 6px;
    margin-right: 16px;
}

.chat .messages .list .message .body p.success span {
    vertical-align: super;
    color: rgba(26, 170, 85, 0.65) !important;
    font-weight: 600;
}

.chat .messages .list .message .body button {
    margin-top: 16px;
}

.chat .new {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 24px 0 0 0;
}

.chat .new .text {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100px;
    margin-bottom: 0 !important;
}

.chat .new button {
    margin-left: 24px;
}

.requestPaymentModal p {
    margin-bottom: 0;
}
