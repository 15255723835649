.layout .calculator p.warning {
    margin-bottom: 12px;
    font-style: italic;
    line-height: 13px;
    font-weight: 600;
    color: rgb(92, 106, 194);
}

.layout .calculator .form label {
    display: inline-block;
    width: 100%;
    margin: 8px 0 0 0;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
}

.layout .calculator .button {
    text-align: right;
    align-self: flex-end;
}

.layout .calculator .button button {
    margin-bottom: 16px;
}

.layout .calculator .buttons button {
    margin-top: 30px;
    font-size: 22px;
    height: 44px;
    padding: 0 24px;
}

.layout .calculator .result {
    margin-top: 36px;
    text-align: center;
}

.layout .calculator .result th {
    font-size: 22px;
    font-weight: 600;
}

.layout .calculator .result td .bmi {
    margin-top: 16px;
    font-size: 56px;
    font-weight: 600;
    color: #1aaa55;
    margin-bottom: 64px;
}

.layout .calculator .result td .description {
    text-align: center;
    font-size: 20px;
    line-height: 34px;
    font-weight: 600;
}

.layout .calculator h3 {
    font-weight: 600;
}

.layout .calculator .info {
    margin-top: 48px;
    font-size: 16px;
}

.layout .calculator .info ul {
    padding-left: 16px;
}
