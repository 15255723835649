.demo {
    background-color: #fff;
    padding: 100px 24px 120px 24px;
}

.demo.dark {
    background-color: #290b39;
    color: #fff;
}

.demo .message {
    height: 100%;
    margin-right: 8px;
    text-align: center;
}

.demo .message h3 {
    margin-bottom: 16px;
    font-size: 34px;
    font-weight: 600;
}

.demo.dark .message h3 {
    color: #fff;
}

.demo .message p {
    font-size: 20px;
    margin-bottom: 16px;
}

.demo.dark .message p {
    color: #fff;
    opacity: 0.65;
}

.demo .form {
    margin-top: 200px;
    text-align: left;
    margin: 120px auto;
    max-width: 450px;
}

.demo .form label {
    margin-bottom: 8px;
    font-size: 20px;
    display: block;
}

.demo .form .email span span:last-child {
    background-color: transparent;
}

.demo .form p {
    margin-top: 4px;
    text-align: left;
}

.demo .legal {
    font-size: 16px;
    line-height: 24px;
}

.demo.dark .legal .label {
    color: #fff;
    opacity: 0.65;
}

.demo .legal .label a {
    font-weight: bold;
}

.demo.dark .legal .label a {
    color: #fff;
    text-decoration: underline;
}

.demo .success img {
    max-width: 100%;
    max-height: 340px;
}

.demo .success p {
    margin-top: 60px;
    font-size: 24px;
}

@media (min-width: 992px) {
    .demo {
        padding: 80px 260px 140px 240px;
    }
}
