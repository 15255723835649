.comments {
    margin: -20px 0 16px 0;
    padding: 4px 16px 16px 16px;
    background-color: #fff;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: rgba(41, 11, 57, 0.25);
    border-radius: 4px;
}

.new {
    margin-bottom: 12px;
}
