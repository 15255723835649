.type h2 {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: left;
}

.type .card {
    margin-top: 8px 12px;
    padding: 16px;
    text-align: left;
    font-size: 16px;
    border: 1px solid rgba(40, 11, 56, 0.3);
    border-radius: 4px;
    cursor: pointer;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.type .card i {
    font-size: 36px;
    margin-right: 24px;
}

.type .card.report {
    color: #fff;
    background-color: rgba(75, 64, 122, 0.65);
    border-color: rgba(75, 64, 122, 0.65);
}

.type .card.laboratory {
    color: #fff;
    background-color: rgba(154, 127, 168, 0.65);
    border-color: rgba(154, 127, 168, 0.65);
}

.type .card.radiology {
    color: #fff;
    background-color: rgba(77, 94, 126, 0.65);
    border-color: rgba(77, 94, 126, 0.65);
}

.type .card.manual {
    color: #fff;
    background-color: rgba(157, 150, 216, 0.65);
    border-color: rgba(157, 150, 216, 0.65);
}

.type .card p {
    margin: 0;
}

.type .card p:first-child {
    font-weight: 600;
}

.type .card p:last-child {
    font-size: 14px;
    opacity: 0.8;
}

.avatar {
    background-color: rgba(75, 64, 122, 0.65);
}

.upload {
    background: #290b39;
    color: #fff;
}

.date {
    margin-bottom: 0 !important;
}

.empty {
    margin-bottom: 0 !important;
}

.label {
    font-size: 16px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.85);
}

.report {
    border: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    background-color: #f3faff;
}

.templates {
    margin-bottom: 16px;
    display: flex;
}

.templates button {
    margin-left: 16px;
}

.templates button:last-child {
    margin-left: 8px;
}

.buttons {
    margin-top: 36px;
}

.buttons .delete {
    color: #ff4d4f !important;
    float: right;
}
