.video {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background: #000;
}

.video .main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100;
}

.video .notification {
    position: absolute;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
}

.video .notification.rejected {
    color: #ff4d4f;
}

.video .notification i {
    margin-right: 24px;
}

.video .audio {
    display: none;
}

.video .toolbar {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0 24px;
}

.video .toolbar .controls button {
    margin-right: 24px;
}

.video .toolbar .controls button.disabled {
    color: #fff !important;
    border-color: #fff;
}

.video .toolbar .stop {
    text-align: right;
}

.video .local {
    position: absolute;
    top: 16px;
    right: 16px;
    height: 125px;
    border: 1px solid #000;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.7);
}

.video .local video {
    width: 100%;
    max-height: 100%;
}

.video .local.portrait video {
    height: 100%;
    max-width: 100%;
}

.video .remote {
    width: 100%;
    height: auto;
    max-height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.video .remote.portrait {
    height: 100%;
    width: auto;
    max-width: 100%;
}

.video .remote video {
    width: 100%;
    max-height: 100%;
}

.video .remote.portrait video {
    height: 100%;
    max-width: 100%;
}
