.layout {
    height: 100%;
}

.cards {
    text-align: center;
    margin-left: -24px !important;
    margin-top: 16px !important;
}

@media (min-width: 1200px) {
    .cards {
        margin-top: 36px !important;
    }
}

.card {
    cursor: pointer;
    border: 0 none;
    border-radius: 8px;
    background-color: transparent;
}

.card:hover {
    opacity: 0.85;
}

.card i {
    font-size: 48px;
    color: #fff;
    background-color: rgba(146, 102, 138, 0.65);
    padding: 24px;
    border-radius: 8px;
}

.card.profile i {
    background-color: rgba(75, 64, 122, 0.65);
}

.card.diary i {
    background-color: rgba(154, 127, 168, 0.65);
}

.card.biometrics i {
    background-color: rgba(77, 94, 126, 0.65);
}

.card.documents i {
    background-color: rgba(157, 150, 216, 0.65);
}

.card.links i {
    background-color: rgba(146, 102, 138, 0.65);
}

.card.chats i {
    background-color: rgba(77, 94, 126, 0.65);
}

.card .meta > div > div:first-child {
    margin-top: 24px;
    margin-bottom: 0;
    display: inline-block;
    font-weight: 500;
    font-size: 20px;
    color: #290b39;
}

.card .meta > div > div:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #290b39;
    line-height: 16px;
    opacity: 0.65;
}
