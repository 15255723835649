.messages .header h1 {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 21px;
}

.messages .header p {
    font-size: 15px;
}

.messages .item {
    align-items: flex-start;
}

.messages .item .desc {
    display: inline-block;
    line-height: 18px;
}
