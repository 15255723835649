.dashboard .header h1 {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 21px;
}

.dashboard .header p {
    font-size: 15px;
}

.dashboard h2 {
    font-size: 18px;
    margin-top: 36px;
    margin-bottom: 24px;
}

.dashboard h3 {
    font-size: 18px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.dashboard .buttons {
    margin-top: 48px;
}

.image {
    text-align: center;
}

.image img {
    max-width: 100%;
    max-height: 500px;
}
