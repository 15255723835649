.empty {
    margin-bottom: 0 !important;
}

.buttons {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    text-align: right;
    background-color: #f8fafb;
}

.buttons button {
    margin-left: 16px;
}

.buttons button:first-child {
    float: left;
    color: #000;
}
