.label {
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.85);
}

.invited {
    margin-top: 24px;
}

.invited h2 {
    color: #009b3f !important;
}

.invited p {
    font-size: 16px;
}

.invited p strong {
    font-weight: 600;
}

.invited .cancel {
    padding-left: 0;
    text-decoration: underline;
    margin-top: 24px;
    color: #ff4d4f !important;
}

span.username {
    opacity: 0.7;
}

.intro {
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 16px;
}

.shared {
    font-size: 16px;
}

.shared i {
    margin-right: 12px;
}

.question {
    font-size: 16px;
    margin-top: 42px;
}

.buttons {
    margin-top: 24px;
}

.buttons .delete {
    color: #ff4d4f !important;
    float: right;
}
