.toolbar {
    display: flex;
    flex-direction: row;
    margin: 16px 0 36px 0;
}

.toolbar .dates {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100px;
}

.toolbar .add {
    margin-left: auto;
}

.toolbar .add button {
    margin-left: 8px;
}

.empty {
    margin: -16px;
}

.empty h2 {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: left;
}

.modal .body {
    padding-top: 24px;
    padding-bottom: 24px;
}

.empty .card,
.modal .card {
    margin-top: 8px 12px;
    padding: 16px;
    text-align: left;
    font-size: 16px;
    border: 1px solid rgba(40, 11, 56, 0.3);
    border-radius: 4px;
    cursor: pointer;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.empty .card i {
    font-size: 36px;
    margin-right: 24px;
}

.modal .card i {
    font-size: 24px;
    margin-right: 16px;
}

.empty .card.appointment,
.modal .card.appointment {
    color: #fff;
    background-color: rgba(75, 64, 122, 0.65);
    border-color: rgba(75, 64, 122, 0.65);
}

.empty .card.medication,
.modal .card.medication {
    color: #fff;
    background-color: rgba(154, 127, 168, 0.65);
    border-color: rgba(154, 127, 168, 0.65);
}

.empty .card.record,
.modal .card.record {
    color: #fff;
    background-color: rgba(77, 94, 126, 0.65);
    border-color: rgba(77, 94, 126, 0.65);
}

.empty .card p {
    margin: 0;
}

.empty .card p:first-child {
    font-weight: 600;
}

.empty .card p:last-child {
    font-size: 14px;
    opacity: 0.8;
}

.diary .date {
    margin-top: 5px;
}

.diary .date > span {
    margin: 0 8px;
    text-align: center;
    font-size: 18px;
}
