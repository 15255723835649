.layout {
    flex-direction: column;
    display: flex;
    min-height: 100%;
}

.layout.chat {
    position: fixed;
    height: 100%;
    width: 100%;
}

.layout .header {
    padding: 0 0 0 16px;
}

.layout.home .header {
    background-color: transparent;
    z-index: 3;
}

.layout .body {
    flex: 1;
    padding: 24px 16px;
}

.layout.home .body {
    padding: 0;
}

.layout .footer {
    padding: 16px;
}

@media (min-width: 768px) {
    .layout .body {
        padding: 24px;
    }

    .layout .header {
        padding: 0 4px 0 24px;
    }

    .layout .footer {
        padding: 16px 24px;
    }
}

@media (min-width: 1200px) {
    .layout .body,
    .layout .footer {
        background: rgba(0, 0, 0, 0.03);
    }
}
