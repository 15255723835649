.tools h2 {
    font-weight: 600;
    margin-bottom: 8px;
}

.tools .item {
    padding-bottom: 8px;
}

.tools .item .title {
    font-size: 16px;
}

.tools .buttons {
    width: 100%;
    margin-top: 16px;
}
