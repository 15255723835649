.diary .toolbar {
    display: flex;
    flex-direction: row;
    margin: 16px 0 36px 0;
}

.diary .toolbar .dates {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100px;
}

.diary .toolbar .dates {
    margin-top: 5px;
}

.diary .toolbar .dates > span {
    margin: 0 16px;
    text-align: center;
    font-size: 18px;
    text-transform: capitalize;
}

.diary .today,
.diary .today:hover {
    background-color: rgba(154, 127, 168, 0.65) !important;
    border-top-color: rgba(154, 127, 168, 1) !important;
}

.diary .today > div,
.diary .today:hover > div {
    background: transparent !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, 0.65) !important;
}

.diary .recorded,
.diary .recorded:hover {
    background-color: rgba(154, 127, 168, 0.3) !important;
    border: 1px solid rgba(154, 127, 168, 1) !important;
}

.diary .recorded > div,
.diary .recorded:hover > div {
    background: transparent !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, 0.65) !important;
}

.diary .recorded .ant-fullcalendar-content {
    padding-top: 16px;
}

.diary .recorded .items {
    overflow: hidden;
}

.diary .recorded .item {
    font-size: 11px;
}

.diary .recorded .item p {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.diary .recorded .item p.appointment {
    color: #290b39;
}

.diary .recorded .item p.medication {
    color: rgba(77, 94, 126, 1);
}

.diary .recorded .more {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    line-height: 24px;
}

@media (min-width: 576px) {
    .diary .recorded,
    .diary .recorded:hover {
        background-color: rgba(154, 127, 168, 0.1) !important;
        border: 0 none !important;
        border-top: 2px solid rgba(154, 127, 168, 1) !important;
    }
}
