.layout .calculator .info {
    padding-top: 20px;
}

.layout .calculator p.warning {
    font-style: italic;
    line-height: 13px;
    font-weight: 600;
    color: rgb(92, 106, 194);
}

.layout .calculator .form {
    padding-bottom: 5px;
}

.layout .calculator .form label {
    display: inline-block;
    width: 100%;
    margin: 8px 0 0 0;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
}

.layout .calculator .form .button {
    align-self: flex-end;
}

.layout .calculator .form .button {
    margin-bottom: 16px;
    margin-top: 40px;
}

.layout .calculator .form .buttons {
    margin-top: 40px;
    font-size: 22px;
    height: 44px;
    padding: 0 24px;
}

.layout .calculator .form .units {
    background-color: rgba(0, 0, 0, 0.75);
    margin-top: 40px;
}

.layout .calculator .result {
    margin-top: 36px;
    text-align: center;
    border: solid;
    border-width: 1px;
    border-radius: 7px;
    border-color: rgba(70, 69, 69, 0.137);
}

.layout .calculator .result .info {
    font-size: 22px;
    font-weight: 600;
}

.result td .midparental {
    margin-top: 16px;
    font-size: 56px;
    font-weight: 600;
    color: #1aaa55;
    margin-bottom: 0;
}

.result td .metadata {
    font-size: 16px;
    margin-bottom: 64px;
}

.result td .description {
    text-align: center;
    font-size: 16px;
    line-height: 34px;
    font-weight: 500;
    margin-top: 12px;
}

.layout .calculator .info {
    margin-top: 60px;
    font-size: 16px;
}

.layout .calculator .info ul {
    padding-left: 16px;
}
